/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
// eslint-disable-next-line import/no-unresolved
import { useTheme, css } from 'styled-components';

import {
  ColorProfiles, DefaultComponentFontAdjustments, FontFamily,
  Season,
} from '@powdr/constants';

/**
 * Color Utilities
 */

export const getPageTheme = (initialTheme, season, isWWTakeover) => {
  const hookTheme = useTheme();
  const theme = (initialTheme) || hookTheme;

  return {
    ...theme,
    ...(isWWTakeover)
      ? (season === Season.SUMMER)
        ? theme.wwAltTheme : theme.wwDefaultTeme
      : (season === Season.SUMMER)
        ? theme.altTheme : theme.defaultTheme,
    ...theme.StaticTheme,
  };
};

export const themeUtil = (themeProp, component, staticProfile) => {
  const themeHook = useTheme();

  // find the root path for stacicTheme or theme color else default to base
  const themeRoot = staticProfile || themeHook?.config?.[component] || ColorProfiles.BASE;

  // 1) check against component specific theme overrides
  // 2) check for theme property (link_txt) under
  // the correct root path (primary, secondary, static, tv etc)

  return themeHook.overrides?.[component]?.[themeProp] || themeHook[themeRoot]?.[themeProp];
};

export const componentColor = (themeProp, component, fallback, staticProfile) => {
  const themeHook = useTheme();
  // See if site has custom component color scheme
  const componentColors = component || {};
  // Used for fallback if custom color is not found in theme
  const colorProfile = themeHook?.config?.[component] || staticProfile || ColorProfiles.BASE;

  // return custom color scheme color
  if (themeHook?.[componentColors]?.[themeProp]) {
    return themeHook[componentColors][themeProp];
  }
  // try to return fallback color from profile, otherwise try to return themeProp from profile
  return themeHook[colorProfile]?.[fallback]
    ? themeHook[colorProfile][fallback]
    : themeHook[colorProfile]?.[themeProp];
};

// Color profiles from Drupal come back as an index 0 - 4,
// this returns the color profile string (i.e. base, primary, etc.) based on the index
export const colorProfileByIndex = (index) => {
  const adjustedIndex = (typeof index === 'string') ? parseInt(index, 10) : index;
  const colorProfileArr = Object.keys(ColorProfiles).map((profile) => ColorProfiles[profile]);
  return (index) ? colorProfileArr[adjustedIndex] : colorProfileArr[0];
};

// the opposite of the above function, returns the index based on the color
export const indexByColorProfile = (profileString) => {
  const colorProfileArr = Object.keys(ColorProfiles).map((profile) => ColorProfiles[profile]);
  return `${colorProfileArr.indexOf(profileString)}`; // TODO: cast to string, components expect string currently, remove later
};

/**
 * Luma value of 0...255, darkest to lightest
 * @param c Hex color in the format #FFFFFF.
 * @return true if brighter than 50%, false if darker than 50%
 */
export const checkLightness = (color) => {
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // from ITU-R BT.709 standard

  if (luma > 128) {
    return true;
  }
  return false;
};

// Lighten - use positive number
// Darken - use negative number
export const colorShade = (hexc, lumc) => {
  let hex = String(hexc).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const lum = lumc || 0;

  // convert to decimal and change luminosity
  let rgb = '#'; let c; let
    i;
  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += (`00${c}`).substr(c.length);
  }

  return rgb;
};

// Converts RBGA transparency value to the Hex equivalent for use with hex colors
export const rgbaTransparencyToHex = (p) => {
  const percent = Math.max(0, Math.min(100, p * 100)); // change RGBA value to percentage
  const intValue = Math.round((percent / 100) * 255); // map percentange to nearest integer (0-255)
  const hexValue = intValue.toString(16); // get hex transparency value
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

/**
 * Easy clamp util to avoid having to re-write clamp css across the project
 * @param {number} lineLimit max number of lines before clamping
 * @returns css variable with all necessary clamp styles
 */
export const clamp = (lineLimit) => css`
  display: -webkit-box;
  line-clamp: ${lineLimit};
  -webkit-line-clamp: ${lineLimit};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

/**
 * Font Utilities
 */

// Returns font-size style based on component type and html tag passed in to utilize
// componentType specific font configurations
export const componentFontSize = (componentType, tag) => `
  font-size: ${useTheme()?.ComponentFontAdjustments?.[componentType]?.[tag]
    || DefaultComponentFontAdjustments[componentType][tag]};
`;

const getSizeValue = (s) => parseInt(s?.split('px')[0], 10);

/**
 *
 * @param {String} font FontFamily option
 * @param {String} component Component used to check overrides
 * @param {String} componentSection Section of component used check for overrides
 * @returns object with font config values (will report overridden font settings if present)
 */
export const getFontConfigValues = (
  font,
  component,
  componentSection,
) => {
  const themeHook = useTheme();
  const overrides = themeHook.fontOverrides?.[component]?.[componentSection] || {};
  const fontSettings = themeHook.fonts?.[overrides?.font]
    || themeHook.fonts?.[font]
    || themeHook.fonts[FontFamily.BASE_FONT];

  return {
    overrides,
    ...fontSettings,
  };
};

/**
 *
 * @param {String} font FontFamily option
 * @param {String} size FontSize option, string in format of 'XXpx'
 * @param {Number} sizeScale Decimal value representing a percentage to
 * scale font size up or down (i.e. 1.5 or 0.6), will round UP to nearest integer
 * @param {String} transform TextTransform option
 * @param {String} component Component used to check overrides
 * @param {String} componentSection Section of component used check for overrides
 * @returns string of CSS properties
 */
export const getFontStyles = (
  font,
  size,
  sizeScale = 1,
  transform,
  component,
  componentSection,
) => {
  const {
    overrides, scaling, defaultSize, textTransform, fontFamily,
  } = getFontConfigValues(font, component, componentSection);

  const scaledSize = Math.ceil(
    getSizeValue(overrides?.size || size || defaultSize)
    * scaling
    * (overrides?.sizeScale || sizeScale),
  );
  const adjustedTextTransform = overrides?.transform || transform || textTransform;

  return `font-family: ${fontFamily};
    font-size: ${scaledSize}px;
    text-transform: ${adjustedTextTransform};`;
};
