import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import { DemoHeaderBanner } from '@powdr/components/demos';
import {
  Season, Components, ThemeProps as Prop,
  FontSize,
  FontFamily,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.HEADER;

const StyledDemoSeasonToggle = styled.div`
  width: 100%;
  height: 100%;
`;

const ToggleSwitchContainer = styled.div`
  display: flex;
`;

const ToggleSwitchStyles = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};

  :hover {
    color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};

    svg {
      fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
      color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
    }
  }

`;

const SummerToggle = styled.div`
  ${ToggleSwitchStyles}
  border-left: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};

  ${({ active }) => ((active) ? `
    color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
    background-color: ${themeUtil(Prop.BTN_BG_ACTIVE, CPNT)};

    svg {
      fill: #ffcb00;
      color: #ffcb00;
    }
    ` : `
    color: ${themeUtil(Prop.BTN_TXT, CPNT)};

    svg {
      fill: ${themeUtil(Prop.BTN_TXT, CPNT)};
      color: ${themeUtil(Prop.BTN_TXT, CPNT)};
    }`)}
`;

const WinterToggle = styled.div`
  ${ToggleSwitchStyles}

  ${({ active }) => ((active) ? `
    color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
    background-color: ${themeUtil(Prop.BTN_BG_ACTIVE, CPNT)};

    svg {
      fill: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
      color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
    }
    ` : `
    color: ${themeUtil(Prop.BTN_TXT, CPNT)};

    svg {
      fill: ${themeUtil(Prop.BTN_TXT, CPNT)};
      color: ${themeUtil(Prop.BTN_TXT, CPNT)};
    }`)}
`;

const ToggleLabel = styled.div`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, undefined)};
  line-height: ${FontSize.REG30};
  padding-left: 5px;
`;

const ToggleIcon = styled(Icon)``;

export const DemoSeasonToggle = ({
  changeTheme,
}) => {
  const [season, setSeason] = useState(Season.WINTER);
  const handleSeasonChange = (s) => {
    setSeason(s);
    changeTheme(s);
  };

  return (
    <DemoHeaderBanner>
      <StyledDemoSeasonToggle
        onClick={
          () => (
            season === Season.SUMMER
              ? handleSeasonChange(Season.WINTER)
              : handleSeasonChange(Season.SUMMER)
          )
        }
      >
        <ToggleSwitchContainer>
          <WinterToggle active={season === Season.WINTER}>
            <ToggleIcon name="dor-snow" height="25" />
            <ToggleLabel>Winter</ToggleLabel>
          </WinterToggle>
          <SummerToggle active={season === Season.SUMMER}>
            <ToggleIcon name="dor-clear" height="25" />
            <ToggleLabel>Summer</ToggleLabel>
          </SummerToggle>
        </ToggleSwitchContainer>
      </StyledDemoSeasonToggle>
    </DemoHeaderBanner>

  );
};

DemoSeasonToggle.propTypes = {
  changeTheme: PropTypes.func.isRequired,
};

DemoSeasonToggle.defaultProps = {

};
