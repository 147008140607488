// eslint-disable-next-line import/no-unresolved
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import SimpleBar from 'simplebar-react';
import { v4 as uuidv4 } from 'uuid';
import 'simplebar-react/dist/simplebar.min.css';

import { Link } from '@powdr/components';
import { Icon } from '@powdr/components/icon';
import { AppContext } from '@powdr/context';

import {
  CloseButton,
  CloseButtonAlt,
  ModalOverlay,
  DialogAlert,
  DialogPage,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  ContentSpacer,
} from './styles';

export const Modal = ({
  children,
  closeModalLink,
  header,
  footer,
  handleCloseModal,
  isDialogAlert,
  isFullScreen,
  colorProfile,
  removeScroller,
  isMiniModal,
}) => {
  const [modalUUID, setModalUUID] = useState(null);
  const [hasNestedModal, setHasNestedModal] = useState(false);
  const modalOverlayRef = useRef();
  const checkIfModalHasNestedModal = () => ((modalOverlayRef?.current) ? modalOverlayRef?.current?.querySelector('.modal-overlay') !== null : false);
  const {
    toggleBodyScroll,
  } = useContext(AppContext);

  useEffect(() => {
    toggleBodyScroll(true);

    return () => {
      toggleBodyScroll(false);
    };
  }, [toggleBodyScroll]);

  useEffect(() => {
    setHasNestedModal(checkIfModalHasNestedModal());
  }, [modalOverlayRef]);

  const onCloseModal = () => {
    if (closeModalLink) {
      navigate(closeModalLink);
    } else {
      handleCloseModal();
    }
  };

  const content = (
    <>
      { header && <DialogHeader>{header}</DialogHeader>}
      <DialogBody>{children}</DialogBody>
      { footer && <DialogFooter>{footer}</DialogFooter>}
    </>
  );

  useEffect(() => {
    setModalUUID(uuidv4());
  }, []);

  return (
    <ModalOverlay
      ref={modalOverlayRef}
      id={`modal-${modalUUID}`}
      className="modal-overlay"
      $colorProfile={colorProfile}
      $modalID={`modal-${modalUUID}`}
      hasNestedModal={hasNestedModal}
      $isMiniModal={isMiniModal}
    >
      { isDialogAlert
        ? (
          <DialogContent
            isDialogAlert={isDialogAlert}
            $colorProfile={colorProfile}
          >
            <DialogAlert $colorProfile={colorProfile}>
              {children}
            </DialogAlert>
          </DialogContent>
        )
        : (
          <DialogPage
            className="dialog-page"
            isFullScreen={isFullScreen}
            $colorProfile={colorProfile}
            $isMiniModal={isMiniModal}
          >
            <ContentSpacer $isMiniModal={isMiniModal}>
              <Link href={closeModalLink}>
                { isFullScreen
                  ? (
                    <CloseButtonAlt
                      aria-label="close"
                      onClick={onCloseModal}
                      type="button"
                      $colorProfile={colorProfile}
                    >
                      <span className="close">Close</span>
                      <span className="button"><Icon name="ui-close-circle" width="36" height="36" /></span>
                    </CloseButtonAlt>
                  )
                  : (
                    <CloseButton aria-label="close" onClick={onCloseModal} type="button" $colorProfile={colorProfile}>
                      <Icon name="ui-close" width="15" height="15" />
                    </CloseButton>
                  )}
              </Link>
            </ContentSpacer>
            <DialogContent
              className="dialog-content"
              $colorProfile={colorProfile}
              $isMiniModal={isMiniModal}
            >
              {(removeScroller) ? content : <SimpleBar style={{ height: '100%' }}>{content}</SimpleBar>}
            </DialogContent>
          </DialogPage>
        )}
    </ModalOverlay>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModalLink: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  handleCloseModal: PropTypes.func,
  isDialogAlert: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  colorProfile: PropTypes.string,
  removeScroller: PropTypes.bool,
  isMiniModal: PropTypes.bool,
};

Modal.defaultProps = {
  closeModalLink: null,
  header: null,
  footer: null,
  handleCloseModal: () => {},
  isDialogAlert: false,
  isFullScreen: false,
  removeScroller: false,
  colorProfile: null,
  isMiniModal: false,
};
