import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import {
  HtmlContentLoader,
  Icon, ImageAttribution, Link, Slider, WysiwygStyleWrapper,
} from '@powdr/components';
import {
  Alignment,
  AlternatingBlockLayoutOptions, BlockTypes, Breakpoints, FontFamily,
  FontSize,
  TextTransformOptions,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { formatDate, getFontStyles } from '@powdr/utils';

// eslint-disable-next-line no-unused-vars
const CPNT = BlockTypes.ARTICLE_PREVIEW;

// styled components
const ContentBlockArticlePreview = styled.div`
  display: flex;
  gap: 6%;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  height: 100%;

  .cb-title {
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  .cb-formatted-content {
    margin-bottom: 15px;
  }

  small {
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG30, undefined, TextTransformOptions.NO_TRANSFORM)};
    margin-bottom: 10px;
  }

  ${Breakpoints.mobile(css`
    flex-direction: column;
    row-gap: 10px;
  `)}
`;

const TopContainer = styled.div``;

const ContentContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  text-align: left;
  ${({ $verticalAlignment }) => ($verticalAlignment) && `justify-content: ${$verticalAlignment};`};

  ${Breakpoints.mobile(css`
    display: flex;
    width: 100%;
    flex-direction: column;
  `)}
`;

const SubheaderPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  .price-header {
    display: flex;
    align-items: center;
    grid-area: 1 / 1 / 2 / 2;
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 0.65, undefined, CPNT, 'header')};
  }

  .price {
    grid-area: 1 / 2 / 2 / 3;
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 0.75, undefined, CPNT, 'header')};
  }

  .price-description {
    display: flex;
    justify-content: center;
    grid-area: 2 / 2 / 3 / 3;
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 0.65, undefined, CPNT, 'header')};
  }
`;

const TextDecoration = styled.span`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 0.5, undefined, CPNT, 'header')};
`;

const StyledLink = styled(Link)`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;

  .link-icon {
    margin-left: 5px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  ${({ $fullWidth }) => (($fullWidth) && 'width: 90%;')};

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

const OuterWrapper = styled.div`
  width: 50%;
  position: relative;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

const CarouselContainer = styled.div`

`;

const ImageContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled(GatsbyImage)`
  display: block;
  grid-area: 1/1;
  width: 100%;
  height: auto;
  z-index: 1;
  max-width: 100%;
`;

const Image = styled.img`
  display: block;
  grid-area: 1/1;
  width: 100%;
  height: auto;
  z-index: 1;
  max-width: 100%;
`;

export const ArticlePreview = ({
  title, copy, subheader, blockType, colorProfile,
  image, imageCarousel, date, endDate, isStaticImageLink, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonIconName, buttonClick,
  alternateBlockLayoutState, textDecoration, formattedContent,
  altButtonEnabled, altButtonText, altButtonLink, isOverlayEnabled,
  price, priceHeader, priceDescription,
  verticalAlignment, alternateButtonStyles,
}) => (
  <ContentBlockArticlePreview
    className={`content-block-${blockType}`}
    colorProfile={colorProfile}
    alternateBlockLayoutState={alternateBlockLayoutState}
  >
    <OuterWrapper>
      {(imageCarousel || image) && (
        <CarouselContainer>
          {(imageCarousel) ? (
            <Slider
              relationships={{ slides: imageCarousel }}
              parentColorProfile={colorProfile}
            />
          ) : (
            <ImageContainer>
              {(image && !isStaticImageLink) && (
              <StyledImage
                image={image.src}
                alt={image?.altText}
              />
              )}
              {(image && isStaticImageLink) && (
              <Image
                src={image.src}
                alt={image?.altText}
              />
              )}
              <ImageAttribution>{image?.attribution}</ImageAttribution>
            </ImageContainer>
          )}
        </CarouselContainer>
      )}
    </OuterWrapper>
    <ContentContainer
      $verticalAlignment={verticalAlignment}
      alternateBlockLayoutState={alternateBlockLayoutState}
    >
      <TopContainer>
        <SubheaderPriceContainer>
          {(subheader) && <WysiwygStyleWrapper className="cb-subheader">{ReactHtmlParser(subheader)}</WysiwygStyleWrapper>}
          {(price) && (
            <PriceInfoContainer>
              <div className="price-header">{priceHeader}</div>
              <div className="price">{price}</div>
              <div className="price-description">{priceDescription}</div>
            </PriceInfoContainer>
          )}
        </SubheaderPriceContainer>
        {(title) && (
        <h3 className="cb-title">
          {(textDecoration) && <TextDecoration>{textDecoration}</TextDecoration>}
          {`${(textDecoration) ? ' / ' : ''}${title}`}
        </h3>
        )}
        {(date) && <small>{`${formatDate(date).month.num}/${formatDate(date).day.num}/${formatDate(date).day.num}${(endDate) ? ` - ${formatDate(endDate).month.num}/${formatDate(endDate).day.num}/${formatDate(endDate).day.num}` : ''}`}</small>}
      </TopContainer>
      {(copy) && <p className="cb-copy">{copy}</p>}
      {(formattedContent) && (
      <WysiwygStyleWrapper className="cb-formatted-content">
        <HtmlContentLoader html={formattedContent} allowRerender />
      </WysiwygStyleWrapper>
      )}
      {(buttonEnabled && buttonText) && (
        <ButtonWrapper
          $fullWidth={altButtonEnabled}
          $alternateBlockLayoutState={alternateBlockLayoutState}
        >
          <StyledLink
            aria-label={ariaLabel}
            role="button"
            className={classNames('button', { 'with-background': !alternateButtonStyles?.includes(Alignment.LEFT) })}
            onClick={() => buttonClick()}
            href={buttonLink}
            target={buttonTarget}
          >
            {buttonText}
            {(buttonIconName) && <Icon className="link-icon" name={buttonIconName} width="13" height="13" />}
          </StyledLink>
          {(altButtonEnabled && !isOverlayEnabled) && (
            <StyledLink className={classNames('button alt-button', { 'with-background': !alternateButtonStyles?.includes(Alignment.RIGHT) })} href={altButtonLink}>
              {altButtonText}
            </StyledLink>
          )}
        </ButtonWrapper>
      )}
    </ContentContainer>
  </ContentBlockArticlePreview>
);

ArticlePreview.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
