import {
  useCallback,
} from 'react';

import { useStaticData } from '@powdr/web/src/hooks';

export const useSeason = () => {
  const { defaultSeason } = useStaticData();

  const checkSeasonality = useCallback((season) => {
    if (!defaultSeason || !season) return true;
    if (defaultSeason) return (season === defaultSeason);
    return true;
  }, [defaultSeason]);

  return {
    checkSeasonality,
    defaultSeason,
  };
};
